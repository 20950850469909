$sBlack-0:#000000;
$sBlack-1:#262626;
$sBlack-2:#0e0909;
$sBlack-3:#4D4D4D;
$sBlack-4:#232323;
$sBlack-5:#464646;

$sWhite:  #FFFFFF;
$sWhite-1: #F0F0F0;
$sWhite-2: rgba(255, 255, 255, .2);
$sWhite-3: rgba(255, 255, 255, .4);
$sWhite-4: rgba(255, 255, 255, .1);
$sWhite-5: rgba(255, 255, 255, .6);
$sWhite-6: rgba(191, 191, 191, .5);
$sWhite-6-1: rgba(191, 191, 191, .2);
$sWhite-7: #F8F8F8;
$sWhite-8: #F3F3F3;

$sGrey-0: #303030;
$sGrey-1: #707070;
$sGrey-1-1: rgba(112, 112, 112, .2);
$sGrey-1-2: rgba(112, 112, 112, .4);
$sGrey-2: #136DC0;
$sGrey-3: #CCCCCC;
$sGrey-4: #E8E9ED;
$sGrey-5: #FDFDFD;
$sGrey-6: #2C2F39;
$sGrey-7: #DEDEDE;
$sGrey-8: #F2F2F2;
$sGrey-9: #CACACA;
$sGrey-10:#E8E8E8;
$sGrey-11:#F9F9F9;
$sGrey-12:#E5E5E5;
$sGrey-13:#C4C4C4;
$sGrey-14:#E0E0E0;
$sGrey-15:#1A1A1A;
$sGrey-16:#A09D96;
$sGrey-16-1:rgba(160, 157, 150, .2);
$sGrey-17:#E7E7E7;
$sGrey-18:#ced4da;
$sGrey-19:#e9ecef;
$sGrey-20:#DFDFDF;
$sGrey-21:#E6E6E6;
$sGrey-22:#BFBFBF;
$sGrey-23:#F5F5F5;
$sGrey-24:#8F8F8F;
$sGrey-25:#D2D2D2;
$sGrey-26:#7D7D7D;
$sGrey-27:#4B4B4B;
$sGrey-28:#C5C5C5;
$sGrey-29:#A3A3A3;
$sGrey-30:#EFEFEF;
$sGrey-31:#7F7F7F;
$sGrey-32:#C8C8C8;
$sGrey-33:#6B6B6B;
$sGrey-34:#9A9A9A;
$sGrey-35:#2A2A2A;
$sGrey-36:#252525;
$sGrey-37:#666666;
$sGrey-38: #DDDDDD;

$sBlue-0: #6080F0;
$sBlue-1: #4A4F63;
$sBlue-2: #60698A;
$sBlue-3: #344DA4;
$sBlue-4: #2196F3;

$sRed-0:  #E86247;
$sRed-1:  #F24E1E;

$sMaroon-0:  #B62F14;

$sGreen-0:#357221;

$sYellow-0:#FFF0B1;
$sYellow-1:#F1D24D; // Delete

$sAmber-0: #F1D24D;
$sAmber-1: #D0B53F;

$sborder-radius-s:  2px;
$sborder-radius-m:  3px;
$sborder-radius:    5px;
$header-height:     70px;

$font-family-base: 'Inter','Arial', 'Segoe UI', 'sans-serif';
$border-grey: 0.063rem solid $sGrey-1;

$input-field-height: 3em;
$icons-root-path: '../../../assets/images/icon';
$max-width-1480: 92.5rem;
$qtr-border-color: rgba(112, 112, 112, 0.2);

$breakpoint-xl: 1608px; // 8 px more to accomodate for custom scrollbar
$breakpoint-l: 1208px; // 8 px more to accomodate for custom scrollbar
$breakpoint-m: 820px; // 8 px more to accomodate for custom scrollbar
$breakpoint-s: 648px; // 8 px more to accomodate for custom scrollbar
$breakpoint-xs: 488px; // 8 px more to accomodate for custom scrollbar

.xxx-small-text {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 55%; //xx-small
}
.xxx-small-text-bold {
  @extend .xxx-small-text;
  font-weight: 500; //xx-small
}

.xx-small-text {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: 65%; //xx-small
}
.xx-small-text-1 {
  font-weight: normal;
  font-size: 62.5%;
}
.xx-small-text-bold {
  @extend .xx-small-text;
  font-weight: 400;
}

.x-small-text-light {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: 70%; //x-small
}

.x-small-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 70%; //x-small
}
.x-small-text-bold {
  @extend .x-small-text;
  font-weight: 600;
}

.smaller-text-light {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: 75%; //smaller
}
.smaller-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 75%; //smaller
}
.smaller-text-normal {
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 75%;
}

.xx-smaller-text-normal {
  @extend .normal-font-family-style;
  font-size: 50%;
  font-weight: 400;
}

.smaller-text-bold {
  @extend .smaller-text;
  font-weight: 500;
}

.small-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 80%; //small
}
.small-text-bold {
  @extend .small-text;
  font-weight: 700;
}

.medium-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 87.5%; //medium
}

.medium-text-2 {
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 87.5%;
}
.medium-text-bold {
  @extend .medium-text;
  font-weight: 600;
}

.ex-medium-text {
  font-family: $font-family-base;
  font-weight: 500;
  font-size: 94%; //15px
}

.large-text-light {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 95%; //large
}
.large-text {
  @extend .larger-text-light;
  font-weight: 500;
   //large
}
.large-text-bold {
  @extend .larger-text-light;
  font-weight: 700; //large
}
.large-text-bold-1 {
  font-weight: 500;
  font-size: 93.8%;
}

.ex-large-text-light {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 100%; //ex-large
}
.ex-large-text {
  font-family: $font-family-base;
  font-weight: 500;
  font-size: 100%; //ex-large
}
.ex-large-text-bold {
  @extend .ex-large-text;
  font-weight: 700; //ex-large
}

.larger-text-light {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 110%; //larger
}
.larger-text-light-1 {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 115%; //larger
}
.larger-text {
  font-family: $font-family-base;
  font-weight: 700;
  font-size: 110%; //larger
}
.larger-text-1 {
  font-family: $font-family-base;
  font-weight: 700;
  font-size: 120%; //larger
}
.x-large-text-light {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 125%; //x-large
}
.x-large-text {
  font-family: $font-family-base;
  font-weight: 500;
  font-size: 125%; //x-large
}
.xx-large-text-light-1 {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 135%; //xx-large
}
.xx-large-text-light {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 140%; //xx-large
}
.xx-large-text {
  font-family: $font-family-base;
  font-weight: 800;
  font-size: 140%; //xx-large
}

.xxx-large-text {
  font-family: $font-family-base;
  font-weight: 600;
  font-size: 160%; //xxx-large
}

.ex-xxx-large-text {
  @extend .xxx-large-text;
  font-size: 175%; //xxx-large
}

.xxxx-large-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 250%; //xxx-large
}

.normal-font-family-style {
  font-family: $font-family-base;
  font-style: normal;
}

.display {
  max-width: 100%;
  width: 100%;
  margin: auto;
  padding: 1em 1.5em;
  min-height: 100vh;
}

.icon-size {
  width: 1.8em;
  height: 1.8em;
}
.icon-size-ex-cl {
  width: 1.4em;
  height: 1.4em;
}

//Home Styling
.home-blue {
  color: $sWhite;
  background: $sBlue-0;
}

.home-grey {
  color: $sGrey-0;
  background: $sGrey-12;
}

.home-page {
  max-width: 100%;
  width: 100%;
  margin: auto;
  padding: 1.5em;
  min-height: calc(100vh - 70px);
}

.page-content {
  padding: 0 .5em;
  margin-bottom: 3rem;
}

// Dropdown Styling
.dropdown-item-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-item-1 {
  color: $sGrey-0;
  @extend .ex-large-text;
  padding: 0 !important;
}

.dropdown-item-2 {
  color: $sGrey-0;
  margin: 0.5rem 0 0.938rem 0;
}

.sDropdown {
  width: 92% !important;
  padding: .47em;
  background-color: $sAmber-0;
  @extend .ex-large-text;
  color: $sGrey-0;
  box-shadow: none;
  border: none;
}

.sDropdown-1 {
  @extend .sDropdown;
  background-color: $sWhite;
}

.p-dropdown-panel {
  width: inherit;
  @extend .ex-large-text;
  background: $sYellow-0;
  color: $sGrey-0;
  border-radius: $sborder-radius;
  padding: 0;
  margin-top: -.091em;
}

.sDropdown-1 .p-dropdown-panel {
  background: $sGrey-23;
}

.p-dropdown {
  border-radius: $sborder-radius;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background:$sAmber-0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: $sWhite;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  position: relative;
  padding: 0.5rem 1rem 0 1rem;

  .dropdown-panel-item-horiz-line {
    border-bottom: 0.063rem solid $sWhite;
    opacity: 0.5;
  }

  &:last-child .dropdown-panel-item-horiz-line {
    border-bottom: 0.063rem solid transparent;
  }

  &.p-highlight .dropdown-panel-item-horiz-line {
    border-bottom: 0.063rem solid transparent;
  }
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
  border-bottom-color: $sGrey-1-1;
  border-top-color: $sGrey-1-1;
}

.sDropdown-1 .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background:$sGrey-28;
}

.p-dropdown-label {
  color: $sGrey-0;
  font-family: $font-family-base;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:last-child {
  border-bottom: none;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
  border-top: none;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0;
}

.p-dropdown:not(.p-disabled):hover {
  box-shadow: 0 0 0 0;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background-color: $sGrey-13;
  opacity: 0.5;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem  $sGrey-13 ;
  border-left: 0.188rem solid $sWhite-1;
  border-right: 0.188rem solid $sWhite-1;
  background: $sGrey-13;
}
::-webkit-scrollbar-thumb {
  background: $sGrey-1;
  border-radius: 0.625rem;
}
// for firefox
html,div{
  scrollbar-width: thin;
  scrollbar-color: $sGrey-1 $sGrey-13;
}

// Popup Dialog styles
.straight-line {
  margin-top: .8em;
  margin-bottom: 0;
  padding-bottom: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.info-straight-line {
  @extend .straight-line;
  width: 100%;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: auto;
  align-items: center;
}

.info-text {
  padding: 0.5em 0 0 2.3em;
}

.info-text-1 {
  padding: .7em 0.5em 0 1.6em;
  line-height: 1.6;
}

.info-text-2 {
  padding: 1em 0 0 1em;
}

.info-text-3 {
  padding: 0 1em;
}

.no-graph-data-msg{
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.625rem;
  color: $sGrey-0;
  margin-right: 0.5rem;
}

//For tooltip
.long-text-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: $sBlue-1;
  bottom: -0.375rem;
  left: 48%;
  margin-left: -0.25rem;
  border-width: 0.625rem 0.625rem 0;
}

.long-text-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: $sBlue-1;
  top: 41%;
  left: -0.375rem;
  margin-top: -.25rem;
  border-width: 0.625rem 0.625rem 0.625rem 0;
}

.long-text-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: $sBlue-1;
  top: 41%;
  right: -0.375rem;
  margin-top: -.25rem;
  border-width: 0.625rem 0 0.625rem 0.625rem;
}

.long-text-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: $sBlue-1;
  top: -0.375rem;
  left: 48%;
  margin-left: -0.25rem;
  border-width: 0 0.625rem 0.625rem;
}

.long-text-tooltip .p-tooltip-text {
  background: $sBlue-1;
  padding: 1.25rem;
  border-radius: 0.188rem;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
  @extend .normal-font-family-style;
  font-weight: 500;
  font-size: 87.5%;
  line-height: 1.375rem;
  color: $sWhite;
}

.clickable-comp-highlight:hover{
  opacity: 0.6;
}

//For auth cards.
$auth-card-width: 43.75rem;
$auth-card-height: 28.75rem;

.auth-card-container-pos {
  margin: auto;
  position: relative;
}

.auth-card-title-txt-font {
  @extend .normal-font-family-style;
  font-weight: 500;
  font-size: 150%;
  line-height: 2.5rem;
  text-align: center;
  color: $sGrey-0;

  @media (max-width: $breakpoint-s) {
    font-size: 120%;
  }
}

.auth-card-title-comp {
  margin: 0 auto;
  margin-bottom: 3rem;

  @media (max-width: $breakpoint-s) {
    margin-bottom: 1rem;
  }
}

.auth-card-content-txt-font {
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 100%;
  line-height: 1.375rem;
}

.auth-card-footer-text-font {
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.375rem;
  text-align: right;
  color: $sWhite;
  opacity: 0.4;
}

.auth-card-content-footer-dim {

  display: inline-block;
}

.auth-card-footer-style {
  padding: 0;
  position: relative;
  bottom: 0;
  margin-top: 3.5rem;

  .login-footer {

    text-align: right;
    color: $sWhite-5;
    background: $sGrey-0;
    border-radius: 0 0 0.188rem 0.188rem;
    padding: 0.563rem 0.688rem 0.563rem 0;

    .footer-text {
      @extend .auth-card-content-footer-dim;
      @extend .auth-card-footer-text-font;
    }
  }
}

.auth-inputtext-password-style{
  height: 3.75rem;

  ::placeholder {
    color: $sGrey-29;
    opacity: 1;
  }

  .input-inputtext {
    width: 100%;
    height: 100%;
    @extend .normal-font-family-style;
    font-weight: normal;
    font-size: 100%;
    line-height: 1.188rem;
    color: $sGrey-0;
    background: $sGrey-10;
    border: 0.063rem solid $sGrey-32;
    box-sizing: border-box;
    border-radius: 0.188rem;
  }

  .p-inputtext:enabled:focus {
    border-color: $sGrey-32;
    box-shadow: 0 0 0 0;
  }

  .p-inputtext:enabled:hover {
    border-color: $sGrey-32;
  }
}