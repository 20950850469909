.no-result-msg {
  margin-top: 1.5em;
  background-color: $sWhite-4;
  display: flex;
  align-items: center;
}

.no-result-text {
  color: $sWhite;
  @extend .small-text;
  padding: 3em;
}

.task-link {
  text-decoration: underline;
  color: $sAmber-0;
  white-space: nowrap;
}

h1 {
  @extend .xxx-large-text;
}

.container-box {
  background-color: $sWhite;
  border-radius: $sborder-radius-m;
  margin: 1.4em 0 0;
  padding: 0.5em 2.5em;
  padding-bottom: 2em;

  h1 {
    margin: 1.5em 0;
  }

  h3 {
    margin-top: 2.5em;
  }

  p {
    font-size: 94%;
    line-height: 1.6em;
    text-align: left;
    color: $sGrey-1;
    margin-bottom: 1.5em;
  }

  ul {
    padding: 0;
  }

  li {
    color: $sGrey-1;
    font-size: 94%;
    list-style-type: disc;
    line-height: 1.6em;
    margin-bottom: 1em;
  }
}

.text-wrapper {
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}